import styled from 'styled-components';

export const CardContainer = styled.article`
  box-sizing: border-box;
  position: relative;  
  height: 100%;
  width: 100%;
  overflow: hidden;  
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;  
  position: absolute;  
  top: 0;
  left: 0;
  z-index: 0; 
`;

export const CardImage = styled.img`
  width: 100%; 
  height: 100%;
  display: block;
  object-fit: cover;  
`;

export const CardWrapper = styled.div`
  position: absolute;  
  bottom: 0;  
  width: 100%;
  padding: 1rem 10px;  
  background-color: rgba(0, 0, 0, 0.7);  
  color: #ffffff;
  z-index: 1; 
`;

export const Title = styled.h1`
  line-height: 1.1;
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  margin-bottom: 1rem;
  text-align: center;
`;

export const Text = styled.p`
  font-size: 18px;
  text-align: center;
`;
