import React from 'react';
import {
  CardContainer,
  ImageWrapper,
  CardImage,
  CardWrapper,
  Title,
  Text,
} from './CardElements';

const Card = ({ title, img, alt, link, children }) => {
  const goToLink = () => {
    if (link) {
      window.open(link);
    }
  };

  return (
    <CardContainer style={link && { cursor: 'pointer' }} onClick={goToLink}>
      <ImageWrapper>
        <CardImage src={img} alt={alt} />
      </ImageWrapper>
      <CardWrapper>
        <Title>{title}</Title>
        <Text>{children}</Text>
      </CardWrapper>
    </CardContainer>
  );
};

export default Card;
