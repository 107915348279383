import {Backdrop, DialogContainer, CloseIcon} from './style'

const Dialog = ({ children, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <Backdrop onClick={onClose}>
      <DialogContainer onClick={e => e.stopPropagation()}>
        <CloseIcon onClick={onClose} />
        {children}
      </DialogContainer>
    </Backdrop>
  );
};

export default Dialog;