import styled from 'styled-components';

export const TrendingContainer = styled.section`
  color: #fbf7f0;
  background: ${({ light }) => (light ? '#f9f9f9' : '#fbf7f0')};
  padding-top: 50px;
  padding-bottom: 130px;
  position: relative;
  z-index: 2;

  @media screen and (max-height: 800px) {
    padding-top: 30px;
    padding-bottom: 60px;
  }


  @media screen and (max-width: 1024px) {
    padding: 60px 0;
  }

  @media screen and (max-width: 768px) {
    padding: 40px 0;
  }
`;

export const TrendingHeadline = styled.h1`
  color: #555555;
  font-size: 2em;
  margin: 0 50px 2em 50px;
  text-transform: uppercase;
  line-height: 1.1;
  letter-spacing: 5px;
  font-weight: 800;

  @media screen and (max-width: 768px) {
    font-size: 1.5em;
    margin: 0 20px 1em 20px;
  }
`;

export const TrendingWrapper = styled.div`
  display: grid;
  align-items: stretch !important;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  grid-row-gap: 50px;
  grid-column-gap: 50px;
  z-index: 2;
  min-height: 550px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 50px;
  place-items: center;
  overflow: hidden;

  @media screen and (max-width: 1500px) {
    grid-column-gap: 20px;
  }

  @media screen and (max-width: 900px) {
    padding: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

export const Column = styled.div`
  border-radius: 20px;
  width: 100%;
  min-height: 500px;
  background: #fbf7f0;
  color: #555555;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.08), -10px -10px 20px rgba(255, 255, 255, 0.6);
  margin: 10px auto;
  transition: 
    transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  will-change: transform, box-shadow;

  &:hover {
    box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.12), -15px -15px 30px rgba(255, 255, 255, 0.7);
    transform: translateY(-10px);
  }

  @media screen and (max-width: 768px) {
    max-width: 90%;
  }
`;



export const Button = styled.button`
  border-radius: 4px;
  background: ${({ primary }) => (primary ? '#4b59f7' : '#0467fb')};
  white-space: nowrap;
  padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.3s ease-out;

  &:hover {
    transition: all 0.3s ease-out;
    background: ${({ primary }) => (primary ? '#0467fb' : '#4b59f7')};
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const FlexBox = styled.div`
  display: 'flex';
  flex-direction: 'column';
  gap: '5px';
  text-align: 'center';
  width: '80%';
  justify-content: 'center';
  margin: 'auto';
  margin-top: '30px';
`;