import React from 'react';
import {
  TrendingContainer,
  TrendingWrapper,
  TrendingHeadline,
  Column,
  Button,
  FlexBox
} from './TrendingElements';
import Card from '../Card';
import Dialog from '../Dialog';

const TrendingSpace = () => {
  const [videoDialogOpen, setVideoDialogOpen] = React.useState(false);

  return (
    <>
    <Dialog isOpen={videoDialogOpen} onClose={() => setVideoDialogOpen(false)}>
      <video width="100%" height="100%" controls>
        <source
          src="http://bosnavet.ba/wp-content/uploads/2023/09/OnFarm-How-to-video_Bosnia.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </Dialog>
    <TrendingContainer>
      <TrendingHeadline>Aktuelno</TrendingHeadline>
      <TrendingWrapper>
        <Column>
          <Card
            img={require('../../assets/images/trending/idexx_alertys.png').default}
            alt="SDMA"
            title="Novo: IDEXX test za gravidnost na farmama"
            link="http://bosnavet.ba/wp-content/uploads/2023/09/21641-OF-Dairy-Cows-Bosnia-Flyer.pdf"
          >
            IDEXX Alertys OnFarm Pregnancy Test - otkriva glikoproteine povezane s trudnoćom (PAG) u punoj krvi (EDTA)
            već 28 dana nakon osjemenjavanja i 70 dana nakon teljenja.
            <FlexBox>
              <Button onClick={(e) => {
              e.stopPropagation();
              setVideoDialogOpen(true);
            }}>IDEXX Alertys OnFarm Pregnancy Test</Button>
            </FlexBox>
          </Card>
        </Column>
        <Column>
          <Card
            img={require('../../assets/images/trending/SDMA.jpg').default}
            alt="SDMA"
            title="NAPRAVITE RAZLIKU – CATALYST ONE biohemijski analizator"
            link="https://www.idexx.com/en/veterinary/analyzers/catalyst-one-chemistry-analyzer/"
          >
            SDMA, CRP, PHBR, Progesteron, Fruktozamin, TT4, Urin P:C – na jednom
            uređaju u svega nekoliko minuta!
          </Card>
        </Column>
        <Column>
          <Card
            img={require('../../assets/images/trending/Pas-krpelj.png').default}
            alt="Pas Krpelj"
            title="Sezona je krpelja i buha!"
          >
            Zaštitite Vaše ljubimce na vrijeme za bezbrižno uživanje u
            proljetnim danima.
          </Card>
        </Column>
        <Column>
          <Card
            img={require('../../assets/images/trending/4DX.png').default}
            alt="4DX"
            title="SNAP 4DX PLUS TEST"
            link="https://www.idexx.com/en/veterinary/snap-tests/snap-4dx-plus-test/"
          >
            Najpouzdaniji test na tržištu za istovremeno otkrivanje 6 vektorskih
            bolesti pasa za samo 8 minuta!
          </Card>
        </Column>
      </TrendingWrapper>
    </TrendingContainer>
    </>
  );
};

export default TrendingSpace;
